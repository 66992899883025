import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  CategoryNewsService,
  NewsCategoryLabel,
} from '../../services/category-news.service';
import { Router } from '@angular/router';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-category-news',
  templateUrl: './category-news.component.html',
  styleUrls: ['./category-news.component.scss'],
})
export class CategoryNewsComponent {
  public NewsCategoryLabel = NewsCategoryLabel;

  @ViewChild('cards') cardsRef: ElementRef<HTMLElement | null> = new ElementRef(null);

  constructor(
    private router: Router,
    public categoryNewsService: CategoryNewsService
  ) {
    this.categoryNewsService.filterValue = this.router.url.substring(1);
    this.categoryNewsService.getPosts();
  }

  timestampToDate(ts: Timestamp) {
    return new Timestamp(ts.seconds, ts.nanoseconds).toDate();
  }

  btoa(string: any) {
    return btoa(String(string));
  }
}
