<app-ong-hero></app-ong-hero>
<app-detail
    text="Inscreva-se e receba todos os conteúdos disponíveis para ONGs"
    link="https://relacionamento.captadores.org.br/ddd-lp-perene-ddd24-download-de-materiais"
    label="Inscreva-se!"
></app-detail>
<app-category-news></app-category-news>
<mat-divider></mat-divider>
<app-no-profit></app-no-profit>
<app-links></app-links>
<app-map></app-map>
<mat-divider></mat-divider>
<app-common-questions></app-common-questions>
<!-- <app-leader></app-leader> -->
<app-depositions></app-depositions>
<app-donors></app-donors>
