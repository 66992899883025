import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { fromEvent, Subscription, tap, throttleTime } from 'rxjs';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input('pagesRef') pagesRef!: ElementRef<HTMLElement | null>;
  @ViewChild('paginator', { static: true }) paginatorRef!: ElementRef<HTMLElement>;

  @Input('direction') direction:
    | 'horizontal'
    | 'vertical' = 'horizontal';

  private lastPagesScrollDetector!: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['direction']) {
      this.paginatorRef.nativeElement.setAttribute('data-direction', changes['direction'].currentValue);
    }

    if (!changes['pagesRef']) return;

    this.lastPagesScrollDetector?.unsubscribe?.();
    this.ngAfterViewInit();
  }

  ngOnInit() {
    const paginator = this.paginatorRef.nativeElement;

    paginator.setAttribute('data-direction', this.direction);

    fromEvent<MouseEvent>(paginator, 'click')
      .pipe(
        tap((event) => {
          const target = event.target as HTMLElement;
          const isChild = paginator.contains(target);
          if (!isChild) return;

          const index = Array.from(paginator.children).indexOf(target);

          const pages = this.pagesRef?.nativeElement;
          if (!pages) return;

          switch (this.direction) {
            case 'horizontal':
              const pageWidth = parseFloat(
                getComputedStyle(pages.children[0]).width
              );
              pages.scrollLeft = pageWidth * index;
              break;
            case 'vertical':
              const pageHeight = parseFloat(
                getComputedStyle(pages.children[0]).height
              );
              pages.scrollTop = pageHeight * index;
              break;
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    const pages = this.pagesRef?.nativeElement;
    const paginator = this.paginatorRef.nativeElement;

    if (!pages) return;

    this.lastPagesScrollDetector = fromEvent<Event>(pages, 'scroll')
      .pipe(
        throttleTime(200),
        tap(() => {
          let index = 0;

          switch (this.direction) {
            case 'horizontal':
              const cardWidth = parseFloat(
                getComputedStyle(pages.children[0]).width
              );
              index = Math.floor(pages.scrollLeft / cardWidth);
              break;
            case 'vertical':
              const cardHeight = parseFloat(
                getComputedStyle(pages.children[0]).height
              );
              index = Math.floor(pages.scrollTop / cardHeight);
              break;
          }

          const indicators = Array.from(paginator.children);
          indicators.forEach((el) => el.removeAttribute('data-active'));
          indicators[index].setAttribute('data-active', '');
        })
      )
      .subscribe();
  }
}
