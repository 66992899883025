<app-hero></app-hero>
<app-countdown></app-countdown>
<app-presentation></app-presentation>
<app-campaigns></app-campaigns>
<app-impact></app-impact>
<app-whatsapp-banner></app-whatsapp-banner>
<app-participate></app-participate>
<app-follow></app-follow>
<app-discover></app-discover>
<!-- <app-news></app-news> -->
<app-category-news></app-category-news>
<app-leader></app-leader>
<app-depositions></app-depositions>
<app-donors></app-donors>