import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  animationFrames,
  EMPTY,
  endWith,
  finalize,
  fromEvent,
  map,
  merge,
  switchMap,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs';

@Directive({
  selector: '[appInertiaSlider]',
})
export class InertiaSliderDirective {
  @Input('appInertiaSliderDirection') direction:
    | 'horizontal'
    | 'vertical'
    | 'both' = 'horizontal';

  static initialized = false;
  static get BASIC_SELECTOR() { return 'app-inertia-slider' as const; }
  static get DIRECTION_SELECTOR() { return `${InertiaSliderDirective.BASIC_SELECTOR}-direction` as const; }

  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.setAttribute(InertiaSliderDirective.BASIC_SELECTOR, '');
    this.el.nativeElement.setAttribute(InertiaSliderDirective.DIRECTION_SELECTOR, this.direction);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.el.nativeElement.setAttribute(InertiaSliderDirective.DIRECTION_SELECTOR, changes['direction'].currentValue);
  }

  ngOnInit() {
    if (InertiaSliderDirective.initialized) return;
    InertiaSliderDirective.initialized = true;

    const style = document.createElement('style');
    style.innerText = `
      [${InertiaSliderDirective.DIRECTION_SELECTOR}="horizontal"] {
        overflow-x: auto;
      }

      [${InertiaSliderDirective.DIRECTION_SELECTOR}="vertical"] {
        overflow-y: auto;
      }

      [${InertiaSliderDirective.DIRECTION_SELECTOR}="both"] {
        overflow: auto;
      }

      [${InertiaSliderDirective.BASIC_SELECTOR}] {
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        cursor: grab;
      }

      [${InertiaSliderDirective.BASIC_SELECTOR}="moving"] {
        scroll-snap-type: unset !important;
      }

      [${InertiaSliderDirective.BASIC_SELECTOR}]:active {
        cursor: grabbing;
      }

      [${InertiaSliderDirective.BASIC_SELECTOR}] * {
        -webkit-user-drag: none;
        -moz-user-drag: none;
        -ms-user-drag: none;
        user-drag: none;
      }
    `;
    document.head.appendChild(style);
  }

  ngAfterViewInit() {
    const slider: HTMLElement = this.el.nativeElement;

    const vector = Object.seal({ x: 0, y: 0 });

    const rawPointerDown$ = fromEvent<PointerEvent>(slider, 'pointerdown');
    const rawPointerMove$ = fromEvent<PointerEvent>(slider, 'pointermove');
    const rawPointerUp$ = merge(
      fromEvent<PointerEvent>(slider, 'pointerup'),
      fromEvent<PointerEvent>(slider, 'pointercancel')
    );

    const pointerDown$ = rawPointerDown$.pipe(
      tap((startEvent) => {
        if (startEvent.button !== 0 || startEvent.pointerType !== 'mouse') return;

        requestAnimationFrame(() => {
          this.el.nativeElement.setAttribute(InertiaSliderDirective.BASIC_SELECTOR, 'moving');
        });
      }),
      tap((startEvent) => slider.setPointerCapture(startEvent.pointerId))
    );

    const pointerUp$ = rawPointerUp$.pipe(
      tap((endEvent) => slider.releasePointerCapture(endEvent.pointerId)),
      finalize(() => {
        const DURATION = 1000;
        const deceleration = Object.freeze({ x: vector.x, y: vector.y });
        let lastTime = 0;

        animationFrames()
          .pipe(
            finalize(() => {
              this.el.nativeElement.setAttribute(InertiaSliderDirective.BASIC_SELECTOR, '');
            }),
            takeUntil(rawPointerDown$),
            map(({ elapsed }) => ({
              elapsed,
              deltaTime: (elapsed - lastTime) / 1000,
              progress: elapsed / DURATION,
            })),
            tap(({ elapsed }) => (lastTime = elapsed)),
            takeWhile(({ progress }) => progress < 1),
            endWith({ elapsed: 0, deltaTime: 0, progress: 1 }),
            map(({ deltaTime }) => {
              if (!deltaTime) {
                vector.x = 0;
                vector.y = 0;
                return vector;
              }

              if (this.direction !== 'horizontal') {
                vector.y -= deceleration.y * deltaTime;
              }

              if (this.direction !== 'vertical') {
                vector.x -= deceleration.x * deltaTime;
              }

              return vector;
            }),
            tap((velocity) => {
              if (this.direction !== 'horizontal') {
                slider.scrollTop -= velocity.y;
              }

              if (this.direction !== 'vertical') {
                slider.scrollLeft -= velocity.x;
              }
            })
          )
          .subscribe();
      })
    );

    const pointerMove$ = rawPointerMove$.pipe(takeUntil(pointerUp$));

    pointerDown$
      .pipe(
        switchMap((startEvent) => {
          if (startEvent.button !== 0 || startEvent.pointerType !== 'mouse') {
            return EMPTY;
          }

          return pointerMove$;
        }),
        map((event) => {
          vector.x = event.movementX;
          vector.y = event.movementY;
          return vector;
        }),
        tap((velocity) => {
          if (this.direction !== 'horizontal') {
            slider.scrollTop -= velocity.y;
          }

          if (this.direction !== 'vertical') {
            slider.scrollLeft -= velocity.x;
          }
        })
      )
      .subscribe();
  }
}
