<ng-container *ngIf="categoryNewsService.posts$ | async as posts">
    <section class="news">
        <div class="on-mobile">
            <h2>Notícias</h2>
            <p>Confira as últimas novidades do Dia de Doar.</p>
        </div>
        <div class="on-desktop">
            <h2>Últimas notícias</h2>
        </div>

        <div class="cards" appInertiaSlider #cards>
            <ng-container *ngFor="let post of posts; let i = index">
                <article class="card" *ngIf="i < 3">
                    <div class="card-banner">
                        <img class="card-image" [src]="post.banner || 'assets/banners/card-default-banner.svg'">
                        <div class="card-tags" appInertiaSlider>
                            <div class="card-tag" *ngFor="let category of post.categorias">
                                {{ NewsCategoryLabel[category] || category }}
                            </div>
                        </div>
                    </div>
                    <div class="card-header" *ngIf="post.banner">
                        <p>{{ timestampToDate(post.data) | date:"dd ' de ' MMMM" }}</p>
                    </div>
                    <div class="card-content">
                        <h3>{{ post.titulo }}</h3>
                        <div class="card-raw-content" [innerHTML]="post.conteudo"></div>
                    </div>
                    <div class="card-footer">
                        <a class="card-link" [routerLink]="'/noticia/' + btoa(post.id)">Ler mais</a>
                    </div>
                </article>
            </ng-container>
        </div>

        <app-paginator class="paginator" [pagesRef]="cardsRef"></app-paginator>

        <a class="blog-link" routerLink="/blog">
            <span>
                Ver mais publicações
                <mat-icon>chevron_right</mat-icon>
            </span>
        </a>
    </section>
</ng-container>
