import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {
  @Input() text!: string;
  @Input() subtitle!: string;
  @Input() label!: string;
  @Input() route!: string;
  @Input() link!: string;
}
