<app-data-hero></app-data-hero>
<!-- <app-impact></app-impact> -->
<app-detail></app-detail>
<app-principal-results></app-principal-results>
<app-results></app-results>
<app-leader></app-leader>
<app-partners></app-partners>
<app-whatsapp-banner></app-whatsapp-banner>
<app-category-news></app-category-news>
<app-depositions></app-depositions>
<app-donors></app-donors>
