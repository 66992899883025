<section class="results">
    <div class="header">
        <div class="on-mobile">
            <h2>Histórico de movimentos</h2>
            <p>
                Conheça os resultados do Dia de Doar no Brasil e inspire-se para
                continuar crescendo!
            </p>
        </div>
        <div class="on-desktop">
            <h2>
                Conheça os resultados do Dia de Doar no Brasil e
                <span class="highlight-1">inspire-se</span>
                para
                <span class="highlight-2">continuar</span>
                crescendo!
            </h2>
        </div>
        <a
            class="button"
            href="https://docs.google.com/spreadsheets/d/1H36JF_Y3LlD-wbjPek8iDYeg1MhW4oEg6vCybEDpAxE/edit?usp=sharing"
            target="_blank"
        >
            <span>
                Ver histórico completo
                <mat-icon>open_in_new</mat-icon>
            </span>
        </a>
    </div>

    <div class="cards" appInertiaSlider #cards>
        <article class="card" *ngFor="let result of resultsService.results$ | async">
            <h3>{{result.ano}}</h3>
            <div class="card-content">
                <h4>Doações On-line</h4>
                <span>{{result.doacoes}}</span>
                <h5>Campanhas Comunitárias</h5>
                <span>{{result.campanhas}}</span>
                <h6>Pessoas alcançadas nas redes sociais</h6>
                <span>{{result.alcance}}</span>
            </div>
        </article>
    </div>

    <app-paginator class="paginator" [pagesRef]="cardsRef"></app-paginator>
</section>
