<section class="leader">
    <div class="content">
        <div class="text">
            <h2>Quer ser líder do Dia de Doar?</h2>
            <p>Assine nossa Newsletter</p>
        </div>
        <form>
            <input type="email" placeholder="Digite seu e-mail" required formControlName="email">
            <button type="submit" (onsubmit)="onSubmit()">Assinar</button>
        </form>
    </div>

    <img class="background-image" src="assets/hero/stamp-yellow.svg" alt="">
    <img class="image" src="assets/mascotes/personagemAmarelo.svg" alt="Mascote Amarelo - Dia de doar">
</section>
