<app-press-hero></app-press-hero>
<app-press-presentation></app-press-presentation>
<mat-divider></mat-divider>
<app-category-news></app-category-news>
<app-resources></app-resources>
<app-quick-facts></app-quick-facts>
<app-detail></app-detail>
<app-media></app-media>
<app-leader></app-leader>
<app-donors></app-donors>
