<section class="donors" [attr.data-expanded]="description">
    <h2>Nossos Doadores</h2>

    <div class="list" *ngIf="donors" appInertiaSlider>
        <div class="donor-category" *ngFor="let categoria of keys" [ngClass]="categoria">
            <p>{{categoria}}</p>
            <div class="donor-category-group">
                <img *ngFor="let donor of donors[categoria]" [src]="donor.icone" [alt]="'Logo ' + donor.nome" />
            </div>
        </div>
    </div>

    <a class="support-link" href="/sobre">
        Quer doar e apoiar a ampliação do Dia de Doar?
    </a>

    <footer>
        <p>
            O Dia de Doar não intermedeia as doações captadas pelas instituições
            participantes do movimento. Ou seja, nós não distribuímos recursos
            financeiros captados. Anualmente lançamos o Edital de Apoio à Filantropia
            Comunitária que seleciona coletivos e ativistas pela cultura de doação.
        </p>

        <div *ngIf="description">
            <h3>Quer doar e apoiar a ampliação do Dia de Doar?</h3>
            <p>
                Nossos parceiros são pessoas físicas, empresas e organizações que
                ajudam a financiar este grande movimento nacional e executar
                estratégias para fortalecimento da cultura de doação no Brasil.
            </p>
            <p>
                Seja com doação direta de recursos ou prestando serviços que nos
                permitam alavancar a campanha nacionalmente, se sua empresa ou
                organização quer ser parceira do Dia de Doar, escreva para
                <a href="mailto:contato@diadedoar.org.br">contato&commat;diadedoar.org.br</a>.
            </p>
        </div>
    </footer>
</section>
