<app-companies-hero></app-companies-hero>
<app-detail
    text="Acesse todas as ferramentas disponíveis para empresas"
    link="https://relacionamento.captadores.org.br/ddd-lp-perene-empresas-download-de-materiais"
    label="Acessar"
></app-detail>
<app-mobilize></app-mobilize>
<app-companies-quick-facts></app-companies-quick-facts>
<app-companies-how></app-companies-how>
<app-leader></app-leader>
<app-category-news></app-category-news>
<app-common-questions></app-common-questions>
<app-depositions></app-depositions>
<app-donors></app-donors>
