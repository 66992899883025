<section class="whatsapp">
    <div class="content">
        <h2 class="on-mobile">
            Venha participar dos nossos grupos de
            <span class="highlight-1">Whatsapp</span>
        </h2>
        <h2 class="on-desktop">
            Participe dos nossos grupos de
            <span class="highlight-1">Whatsapp</span>
        </h2>
        <a href="https://chat.whatsapp.com/BmlPkGe13oxKAne1zM30sI"
            target="_blank">
            <mat-icon class="icon" svgIcon="whatsapp"></mat-icon>
            Clique aqui
        </a>
    </div>

    <img class="image" src="assets/mascotes/personagemVerde.svg" alt="Mascote Verde - Dia de doar">
</section>
