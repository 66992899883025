import { Component, ElementRef, ViewChild } from '@angular/core';
import { ResultsService } from '../../services/results.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent {
  @ViewChild('cards') cardsRef: ElementRef<HTMLElement | null> = new ElementRef(null);

  constructor(public resultsService: ResultsService) {}
}
