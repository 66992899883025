<section class="detail">
    <div class="content" *ngIf="text || subtitle">
        <h2 *ngIf="text">{{text}}</h2>
        <p *ngIf="subtitle">{{subtitle}}</p>
    </div>
    <div class="content" *ngIf="!text && !subtitle">
        <h2>Quer conhecer mais detalhes?</h2>
        <p>Acesse nosso Relatório de Atividades de 2022</p>
    </div>

    <a *ngIf="!route && !link" class="button" routerLink="/resultados" routerLinkActive="router-link-active">Acessar Relatório</a>
    <a *ngIf="route && !link" class="button" [routerLink]="[route]" routerLinkActive="router-link-active">{{label}}</a>
    <a *ngIf="link" class="button" [href]="link" target="_blank">{{label}}</a>
</section>
