import { Component, Input } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html',
  styleUrls: ['./donors.component.scss'],
})
export class DonorsComponent {
  @Input() description: boolean = false;
  donors!: any;

  donorPriority: Record<string, number> = {
    'Amado': 1,
    'Fantástico': 2,
    'Maravilha': 3,
    'Engajado': 4,
    'Apoio Institucional': 5
  };

  constructor(private dashboardService: DashboardService) {
    this.dashboardService.dashboard.subscribe((res) => {
      if (!res) return;
      this.donors = this.groupBy(res.doadores, 'categoria');
    });
  }

  groupBy(input: any, key: any) {
    return input.reduce((acc: any, currentValue: any) => {
      let groupKey = currentValue[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {});
  }

  get keys() {
    return Object.keys(this.donors).sort(
      (category, next) =>
        this.donorPriority[category] - this.donorPriority[next]
    );
  }
}
