<section class="follow">
    <div class="content">
        <h2>Siga-nos para mais informações sobre o movimento</h2>
        <div class="cards">
            <a href="https://instagram.com/diadedoar/" target="_blank">
                <mat-icon class="icon" svgIcon="instagram"></mat-icon>
            </a>
            <a href="https://www.linkedin.com/showcase/diadedoar/" target="_blank">
                <mat-icon class="icon" svgIcon="linkedin"></mat-icon>
            </a>
            <a href="https://youtube.com/diadedoarbrasil" target="_blank">
                <mat-icon class="icon" svgIcon="youtube"></mat-icon>
            </a>
            <a href="https://twitter.com/diadedoar" target="_blank">
                <mat-icon class="icon" svgIcon="twitter"></mat-icon>
            </a>
            <a href="https://facebook.com/diadedoar/" target="_blank">
                <mat-icon class="icon" svgIcon="facebook"></mat-icon>
            </a>
        </div>
    </div>

    <img class="background-image" src="assets/hero/stamp.svg" alt="">
    <img class="image" src="assets/mascotes/personagemAzul.svg" alt="Mascote Azul - Dia de doar">
</section>
